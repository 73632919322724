import React from "react";
import styled from "styled-components";
import DataImport from "../DataImport/DataImport";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  return (
    <Main>
      <DataImport />
    </Main>
  );
}
