import React, { useContext } from "react";
import styled from "styled-components";
import { AiOutlineMenu } from "react-icons/ai";
import { UIContext } from "../../contexts/UIContext";
import CompetitionList from "../Competition/CompetitionList";
import SeasonList from "../Season/SeasonList";
import SelectUI from "../UI/Form/SelectUI";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../Theme/Hyper";
const Main = styled.div`
  height: 60px;
  position: fixed;
  top: 0px;
  right: 0;
  left: ${(props) => (props.expanded ? "230px" : "0px")};
  z-index: 1030;
  background-color: #fff;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
`;

const MenuIcon = styled.div`
  width: 60px;
  height: 60px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  line-height: 1.5;
  color: #868ba1;
  cursor: pointer;
`;
const Profile = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 2em;
  align-items: center;
`;

const Head = styled.div`
  width: 30px;
  height: 30px;
  background-color: #944aff;
  border-radius: 50%;
`;

const Name = styled.div`
  margin-right: 0.5em;
  color: #868e96;
  font-size: 0.8em;
`;

export default function Header() {
  const {
    setShowSideBar,
    showSideBar,
    setSelectedCompetition,
    setSelectedSeason,
    selectedCompetition,
    selectedSeason,
  } = useContext(UIContext);
  const { user, signOut } = useContext(UserContext);

  return (
    <Main expanded={showSideBar}>
      <MenuIcon
        onClick={() => {
          setShowSideBar(!showSideBar);
        }}
      >
        <AiOutlineMenu />
      </MenuIcon>
      {/* <CompetitionList
        selectedCompetition={selectedCompetition}
        onSelect={setSelectedCompetition}
        style={{ marginLeft: "1em", width: "300px" }}
      />
      <SeasonList
        selectedSeason={selectedSeason}
        onSelect={(season) => {
          setSelectedSeason(season);
        }}
        competition={selectedCompetition}
        style={{ marginLeft: "1em", width: "200px" }}
      /> */}
      <Profile>
        <Name>{user?.username}</Name>
        <Button
          onClick={() => {
            signOut();
          }}
        >
          Sign Out
        </Button>
      </Profile>
    </Main>
  );
}
