/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Grid from "styled-components-grid";
import FixtureList from "../Fixture/FixtureList";
import { Button } from "../../Theme/Hyper";
import CreateFixture from "../Modals/CreateFixture/CreateFixture";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
import Calendar from "../Fixture/Calendar";
import { UIContext } from "../../contexts/UIContext";
import Balloon from "../UI/Balloon/Balloon";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      competition {
        _id
        name
        badge
        competition_type
      }
      home_fighter {
        _id
        first_name
        last_name
        first_name_upper
        last_name_upper
        short_name
        short_name_upper
        weight
        shorts_colour
        image
        image_2
        stories
        nationality {
          _id
          code
          name
        }
      }
      away_fighter {
        _id
        first_name
        last_name
        first_name_upper
        last_name_upper
        short_name
        short_name_upper
        weight
        shorts_colour
        image
        image_2
        stories
        nationality {
          _id
          code
          name
        }
      }
    }
  }
`;

const GAMES_BY_MONTH = gql`
  query Games($date: String) {
    gamesByMonth(date: $date) {
      _id
      date
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 275px;
  margin-top: 52px;
  margin-bottom: 52px;
`;

const Rounds = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;
`;
const RoundMain = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgb(43 43 43 / 38%);
  margin: 0.5em;
  cursor: pointer;
  min-height: 15px;
  min-width: 25px;
  background-color: ${(props) => (props.selected ? "#14ec8e" : "")};
  color: ${(props) => (props.selected ? "#fff" : "")};
`;

export default function Fixtures() {
  // TODO - ADDD LOADING AND ERROR STATES
  const [getFixtures, { data }] = useLazyQuery(GAMES_BY_DATE, {
    fetchPolicy: "network-only",
  });
  const [getMonthFixtures, { data: month_data }] = useLazyQuery(
    GAMES_BY_MONTH,
    {
      fetchPolicy: "network-only",
    }
  );
  const { deleteFixture } = useContext(DataContext);
  const { selectedSeason } = useContext(UIContext);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [fixtures, setFixtures] = useState();
  const [monthFixtures, setMonthFixtures] = useState();
  const [showCreate, setShowCreate] = useState();

  useEffect(() => {
    if (selectedDate) {
      getData();
    }
  }, [selectedDate, getFixtures]);

  useEffect(() => {
    if (selectedMonth) {
      getMonthData();
    }
  }, [selectedMonth, getMonthFixtures]);

  useEffect(() => {
    if (data) {
      setFixtures([...(data.gamesByDate || [])]);
    }
  }, [data]);

  useEffect(() => {
    if (month_data) {
      setMonthFixtures([...(month_data?.gamesByMonth || [])]);
    }
  }, [month_data]);

  function getData() {
    getFixtures({
      variables: { date: selectedDate.format("YYYY-MM-DD") + "Z" },
    });
  }

  function getMonthData() {
    getMonthFixtures({
      variables: { date: selectedMonth.format("YYYY-MM-DD") + "Z" },
    });
  }

  return (
    <Main>
      {!selectedSeason && <Balloon>Select a competition & season</Balloon>}

      {selectedSeason && (
        <Calendar
          onChangeDate={setSelectedDate}
          onActiveStartDateChange={({ activeStartDate, value }) => {
            setSelectedMonth(moment(activeStartDate));
          }}
          highlights={monthFixtures?.map((m) => m.date)}
        />
      )}

      {selectedSeason && (
        <Content>
          <Grid style={{ marginTop: "1em", marginLeft: "1em" }}>
            <Grid.Unit size={1 / 1}>
              <Button
                green
                onClick={() => setShowCreate(true)}
                style={{ width: "200px" }}
              >
                + Create Fight
              </Button>
            </Grid.Unit>
          </Grid>
          <Grid style={{ marginTop: "1em" }}>
            <Grid.Unit size={1 / 1}>
              {fixtures && fixtures.length > 0 && (
                <Panel>
                  <PanelTitle title="Fights">
                    {moment(selectedDate).format("dddd Do  MMMM YYYY")}
                  </PanelTitle>
                  <PanelContent>
                    <FixtureList
                      fixtures={fixtures}
                      onDelete={(fixtureId) => {
                        deleteFixture({ fixtureId: fixtureId }).then(() => {
                          getData();
                        });
                      }}
                    />
                  </PanelContent>
                </Panel>
              )}
            </Grid.Unit>
          </Grid>
        </Content>
      )}
      <CreateFixture
        show={showCreate}
        onCancel={() => {
          setShowCreate(false);
        }}
        onSaved={() => {
          getData();
          setShowCreate(false);
        }}
      />
    </Main>
  );
}

function Round({ round, selectRound, selectedRound }) {
  let displayName = round;
  if (round === 16) {
    displayName = "Semi Final";
  } else if (round === 17) {
    displayName = "Final";
  }
  return (
    <RoundMain
      selected={selectedRound === round}
      onClick={() => {
        selectRound(round);
      }}
    >
      {displayName}
    </RoundMain>
  );
}
