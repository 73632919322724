import React, { useContext } from "react";

import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import Input from "../UI/Form/Input";

const Main = styled.div`
  border-color: #dee2e6;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px !important;
  width: 100%;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
const CATEGORIES = ["tries", "goals"];

export default function PlayerSeasonStats({ player, style, onUpdate }) {
  const { updateSeasonStats } = useContext(DataContext);
  let stats = player.stats;
  return (
    <Main style={style}>
      <Table>
        <thead>
          <tr>
            <TH>Name</TH>
            <TH>Value</TH>
          </tr>
        </thead>
        <tbody>
          {CATEGORIES.map((cat, index) => {
            return (
              <tr>
                <td width={"50%"}>{cat}</td>
                <td width={"100%"}>
                  <Input
                    value={stats?.[cat]}
                    onChange={(e) => {
                      let new_stats = { ...stats };
                      new_stats[cat] = parseInt(e.currentTarget.value);
                      let new_player = { ...player };
                      new_player.stats = new_stats;
                      updateSeasonStats({
                        id: player._id,
                        stats: new_stats,
                      });
                      onUpdate(new_player);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Main>
  );
}
