import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function VenueSelect({ stadium, onChange }) {
  const [stadiums, setStadiums] = useState();
  const { getStadium } = useContext(DataContext);
  useEffect(() => {
    getStadium().then((data) => {
      setStadiums(
        [...data].sort((a, b) => {
          if (a.opta_name < b.opta_name) {
            return -1;
          }
          if (a.opta_name > b.opta_name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, []);
  return (
    <Main>
      <SelectContainer
        value={
          stadium && {
            value: stadium._id,
            label: stadium.name || stadium.opta_name,
          }
        }
        options={
          stadiums &&
          stadiums.map((t) => {
            return { value: t._id, label: t.name || t.opta_name };
          })
        }
        onChange={(e) => {
          onChange(stadiums.find((t) => t._id === e.value));
        }}
        placeholder="Select stadium"
      />
    </Main>
  );
}
