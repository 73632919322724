import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import Lineup from "../Lineup/Lineup";
import PlayerEditor from "../Modals/PlayerEditor";

const Main = styled.div`
  width: 100%;
`;

export default function Squad({ team, squad, getData, showActive }) {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  return (
    <Main>
      <Lineup
        showActive={showActive}
        squad={squad}
        selectPlayer={setSelectedPlayer}
        team={team}
        onChange={() => {
          getData();
        }}
      />
      <PlayerEditor
        team={team}
        onSave={() => {}}
        squadPlayer={
          squad &&
          selectedPlayer &&
          squad.find((s) => {
            return s.player._id === selectedPlayer._id;
          })
        }
        player={selectedPlayer}
        selectPlayer={(p) => {
          setSelectedPlayer(p);
          getData();
        }}
        onCancel={() => {
          setSelectedPlayer();
        }}
      />
    </Main>
  );
}
