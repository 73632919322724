import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import CompetitionList from "../Competition/CompetitionList";
import SeasonList from "../Season/SeasonList";
import { UIContext } from "../../contexts/UIContext";
import { Button, ScrollBar } from "../../Theme/Hyper";
const Main = styled.div`
  width: 250px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;

  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;
const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;

const Scroll = styled(ScrollBar)`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;
export default function TeamList({ fighters, onUpdate }) {
  const history = useHistory();
  const { seasonId, teamId } = useParams();
  const [selectedTeam, setSelectedTeam] = useState();
  const [fightersArray, setFightersArray] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [selectedSeason, setSelectedSeason] = useState();
  const [seasons, setSeasons] = useState([]);
  const { updatePlayer, addFighterToSeason, getSeason } =
    useContext(DataContext);

  useEffect(() => {
    getSeason().then((data) => {
      setSeasons(data);
      if (selectedCompetition) {
        let newSeason = data
          ?.filter((s) => s.competition._id === selectedCompetition._id)
          ?.sort((a, b) => {
            return (
              parseInt(b.name.split("/")[0]) - parseInt(a.name.split("/")[0])
            );
          })[0];
        setSelectedSeason(newSeason);
      }
    });
  }, [selectedCompetition, fighters]);

  useEffect(() => {
    getFighters();
  }, [selectedSeason, fighters]);

  useEffect(() => {
    if (seasonId && seasons?.length) {
      setSelectedSeason(seasons?.find((s) => s._id === seasonId));
      setSelectedCompetition(
        seasons?.find((s) => s._id === seasonId)?.competition
      );
    }
  }, [seasonId, seasons]);

  const getFighters = useCallback(() => {
    let newFighters = [];

    for (
      let i = 0;
      i < (selectedSeason?.fighters ? selectedSeason?.fighters.length : 0);
      i++
    ) {
      newFighters.push(
        fighters?.find((t) => t._id === selectedSeason.fighters[i]._id)
      );
    }
    newFighters = newFighters.sort((a, b) => {
      var textA = a.last_name.toUpperCase();
      var textB = b.last_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setFightersArray(newFighters);
  }, [selectedSeason, fighters]);

  const createNewTeam = useCallback(() => {
    updatePlayer({
      first_name: "Fighter",
      last_name: (fightersArray.length + 1)?.toString(),
    }).then((player) => {
      setSelectedTeam(player._id);

      addFighterToSeason({
        season: selectedSeason._id,
        fighter: player._id,
      }).then((data) => {
        onUpdate();
        history.push("/pfl/Fighters/" + player._id + "/" + selectedSeason._id);
      });
    });
  }, [
    selectedSeason,
    fightersArray,
    addFighterToSeason,
    updatePlayer,
    history,
  ]);

  return (
    <Main>
      <CompetitionList
        selectedCompetition={selectedCompetition}
        onSelect={setSelectedCompetition}
        style={{ margin: "1em" }}
      />
      <SeasonList
        seasons={seasons}
        selectedSeason={selectedSeason}
        onSelect={(season) => {
          setSelectedSeason(season);
        }}
        competition={selectedCompetition}
        style={{ margin: "1em" }}
      />
      {selectedSeason && (
        <Button
          style={{ marginBottom: "1em", marginRight: "0px" }}
          green
          onClick={() => {
            createNewTeam();
          }}
        >
          + Create Fighter
        </Button>
      )}
      <Scroll>
        <Ul>
          {fightersArray?.map((fighter) => {
            return (
              <Li>
                <A
                  to={
                    "/pfl/Fighters/" + fighter?._id + "/" + selectedSeason?._id
                  }
                  selected={fighter?._id === teamId}
                  selectedBG={fighter?._id === teamId}
                  onClick={() => {
                    setSelectedTeam(fighter?._id);
                  }}
                >
                  {fighter?.first_name} {fighter?.last_name}
                </A>
              </Li>
            );
          })}
        </Ul>
      </Scroll>
    </Main>
  );
}
