import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function TeamSelect({
  season,
  homeFighter,
  awayFighter,
  onChangeHomeFighter,
  onChangeAwayFighter,
}) {
  const [fighters, setFighters] = useState();

  useEffect(() => {
    setFighters(season?.fighters);
  }, [season]);
  return (
    <Main>
      <SelectContainer
        value={
          homeFighter && {
            value: homeFighter._id,
            label: homeFighter.first_name + " " + homeFighter.last_name,
          }
        }
        options={
          fighters &&
          fighters.map((t) => {
            return { value: t._id, label: t.first_name + " " + t.last_name };
          })
        }
        onChange={(e) => {
          onChangeHomeFighter(fighters.find((t) => t._id === e.value));
        }}
        placeholder="Select red fighter"
      />
      v
      <SelectContainer
        value={
          awayFighter && {
            value: awayFighter._id,
            label: awayFighter.first_name + " " + awayFighter.last_name,
          }
        }
        options={
          fighters &&
          fighters.map((t) => {
            return { value: t._id, label: t.first_name + " " + t.last_name };
          })
        }
        onChange={(e) => {
          onChangeAwayFighter(fighters.find((t) => t._id === e.value));
        }}
        placeholder="Select blue fighter"
      />
    </Main>
  );
}
