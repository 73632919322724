import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function CompetitionSelect({
  competition,
  season,
  onChange,
  onChangeSeason,
}) {
  const [competitions, setCompetitions] = useState();
  const [seasons, setSeasons] = useState();
  const { getCompetition, getSeason } = useContext(DataContext);
  useEffect(() => {
    getCompetition().then((data) => {
      setCompetitions(data);
      getSeason().then((data) =>
        setSeasons(
          data.filter((s) => {
            return s.competition._id === competition?._id;
          })
        )
      );
    });
  }, [competition]);
  return (
    <Main>
      <SelectContainer
        value={
          competition && {
            value: competition._id,
            label: competition.name,
          }
        }
        options={
          competitions &&
          competitions.map((t) => {
            return { value: t._id, label: t.name };
          })
        }
        onChange={(e) => {
          onChange(competitions.find((t) => t._id === e.value));
        }}
        placeholder="Select competition"
      />
      <SelectContainer
        value={
          season && {
            value: season._id,
            label: season.name,
          }
        }
        options={
          seasons &&
          seasons.map((t) => {
            return { value: t._id, label: t.name };
          })
        }
        onChange={(e) => {
          onChangeSeason(seasons.find((t) => t._id === e.value));
        }}
        placeholder="Select season"
      />
    </Main>
  );
}
