import React, { useState } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import moment from "moment";
const CalendarDiv = styled(Calendar)``;

export default function CalendarUI({
  onChangeDate,
  onActiveStartDateChange,
  date,
  highlights,
}) {
  return (
    <CalendarDiv
      minDetail={"month"}
      formatShortWeekday={(locale, date) => moment(date).format("dd")}
      prev2Label={null}
      next2Label={null}
      onChange={(val) => {
        onChangeDate(moment(val.valueOf()));
      }}
      onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
        onActiveStartDateChange({ activeStartDate, value });
      }}
      value={new Date(date.valueOf())}
      tileClassName={({ date, view }) => {
        let match = highlights?.find((x) => {
          let d2 = moment(date).format("YYYY-MM-DD") + "Z";
          return x === d2;
        });

        if (match) {
          return "highlight";
        }
      }}
    />
  );
}
