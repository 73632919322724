import React, { useState, useContext, useEffect } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import Headshot from "../UI/Profile/Headshot";
import moment from "moment";
import PlayerSeasonStats from "./PlayerSeasonStats";
import PlayerGameStats from "./PlayerGameStats";
import styled from "styled-components";
import Stories from "./Stories";
import SelectUI from "../UI/Form/SelectUI";
import { DataContext } from "../../contexts/DataContext";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { CgColorPicker } from "react-icons/cg";
import { SketchPicker } from "react-color";

const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

export default function PlayerEdit({ player, team, onChange }) {
  const [view, setView] = useState(4);
  const [countries, setCountries] = useState();
  const { getCountry } = useContext(DataContext);

  useEffect(() => {
    getCountry({}).then((countries) => {
      setCountries(countries);
    });
  }, []);

  function updatePlayer(player) {
    onChange(player);
  }

  function toFeet(n) {
    var realFeet = (n * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches + "''";
  }

  return (
    <Grid>
      <Grid>
        <Grid.Unit size={7 / 8}>
          {(player?.type?.toLowerCase() === "manager" ||
            player?.type?.toLowerCase() === "coach") && (
            <Grid>
              <Grid.Unit size={1 / 6}>
                <Input
                  value={player?.player?.title}
                  title="Title"
                  onChange={(e) => {
                    let newPlayer = { ...player.player, title: e.target.value };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
            </Grid>
          )}
          <Grid>
            <Grid>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.first_name}
                  title="First Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      first_name: e.target.value,
                    };
                    newPlayer.first_name_upper =
                      newPlayer.first_name.toUpperCase();
                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.last_name}
                  title="Last Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      last_name: e.target.value,
                    };
                    newPlayer.last_name_upper =
                      newPlayer.last_name.toUpperCase();
                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.first_name_upper}
                  title="First Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      first_name_upper: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.last_name_upper}
                  title="Last Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      last_name_upper: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.short_name || player.player?.last_name}
                  title="Short Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      short_name: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={
                    player.player?.short_name_upper ||
                    player.player?.last_name_upper
                  }
                  title="Short Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      short_name_upper: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              {player?.type?.toLowerCase() !== "manager" &&
                player?.type?.toLowerCase() !== "coach" && (
                  <>
                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.shirt_number}
                        title="Shirt Number"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player,
                            shirt_number: e.target.value,
                          };
                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <SelectUI
                        value={
                          (player?.player?.weight && {
                            value: player?.player?.weight,
                            label: player?.player?.weight,
                          }) ||
                          null
                        }
                        options={[
                          { value: "LHW", label: "LHW" },
                          { value: "WFLY", label: "WFLY" },
                          { value: "BW", label: "BW" },
                        ]}
                        title="Weight"
                        placeholder="Select Weight"
                        onChange={(weight) => {
                          let newPlayer = {
                            ...player.player,
                            weight,
                          };

                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <SelectUI
                        value={{
                          value: player?.player?.nationality,
                          label: player?.player?.nationality
                            ? player?.player?.nationality?.name +
                              " (" +
                              player?.player?.nationality?.code +
                              ")"
                            : null,
                        }}
                        options={
                          countries &&
                          countries.map((c) => {
                            return {
                              value: c,
                              label: c.name + " (" + c.code + ")",
                            };
                          })
                        }
                        title="Nationality"
                        placeholder="Select Nationality"
                        onChange={(country) => {
                          let newPlayer = {
                            ...player.player,
                            nationality: country,
                          };

                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>

                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.player?.image || ""}
                        title="Fighter image"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player.player,
                            image: e.target.value,
                          };

                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.player?.image_2 || ""}
                        title="Fighter image 2"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player.player,
                            image_2: e.target.value,
                          };

                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <ColourPicker
                        title="Shorts colour"
                        value={player?.player?.shorts_colour || ""}
                        onChange={(value) => {
                          let newPlayer = {
                            ...player.player,
                            shorts_colour: value,
                          };
                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid.Unit>
      </Grid>
      <Stories
        player={JSON.parse(JSON.stringify(player))}
        onUpdate={(player) => {
          updatePlayer({ ...player });
        }}
      />
    </Grid>
  );
}
const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const Popup = styled.div`
  position: absolute;
  z-index: 100;
`;

function ColourPicker({ value, onChange, title }) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={title}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => setOpen(!open)}
        >
          {!value && <CgColorPicker />}
        </Swatch>

        {open && (
          <Popup>
            <Cover onClick={handleClose} />
            <SketchPicker
              color={value || "#fff"}
              onChange={(colour) => {
                onChange(colour.hex);
              }}
            />
          </Popup>
        )}
      </div>
    </ColourContainer>
  );
}
