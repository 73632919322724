import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import SeasonEdit from "../Season/SeasonEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function SeasonEditor({
  season,
  teams,
  competitions,
  onSave,
  onCancel,
  selectSeason,
}) {
  const { updateSeason, deleteSeason } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={season}
      onCancel={() => {
        selectSeason();
        onCancel();
      }}
    >
      {season && (
        <Panel style={{ height: "300px" }}>
          <PanelTitle title={"Season Edit"}>
            <div>{season._id}</div>
          </PanelTitle>
          <PanelContent>
            <SeasonEdit
              competitions={competitions}
              teams={teams}
              season={season}
              onChange={(s) => {
                selectSeason(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={season._id}
            onDelete={() => {
              deleteSeason({ data: season }).then(() => {
                selectSeason();
                onSave();
              });
            }}
            onSave={() => {
              updateSeason({ data: season }).then(() => {
                selectSeason();
                onSave();
              });
            }}
            onCancel={() => {
              selectSeason();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
