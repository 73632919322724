import React, { useState, useEffect, useContext } from "react";
import TeamList from "../Team/TeamList";
import styled from "styled-components";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";
import PlayerEdit from "../Player/PlayerEdit";
import PanelTitle from "../UI/Panel/PanelTitle";
import Panel from "../UI/Panel/Panel";
import Grid from "styled-components-grid";
import PanelContent from "../UI/Panel/PanelContent";
import SavePanel from "../UI/Form/SavePanel";

const Main = styled.div`
  display: flex;
  background-color: #ececec;
`;
const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
`;
export default function Teams() {
  const [teams, setTeams] = useState();
  const { getPlayers } = useContext(DataContext);
  let match = useRouteMatch();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getPlayers().then((data) => {
      setTeams(data);
    });
  }

  return (
    <Main>
      <>
        <Switch>
          <Route path={`${match.path}/:teamId/:seasonId`}>
            <TeamList fighters={teams} onUpdate={getData} />
            <Content>
              <Player fighters={teams} onUpdate={getData} />
            </Content>
          </Route>
          <Route path={`${match.path}`}>
            <TeamList fighters={teams} onUpdate={getData} />
          </Route>
        </Switch>
      </>
    </Main>
  );
}

const PlayerMain = styled.div`
  margin-left: 250px;
  margin-top: 52px;
  margin-bottom: 52px;
`;
function Player({ fighters, onUpdate }) {
  let { teamId } = useParams();
  const { updatePlayer } = useContext(DataContext);
  let [editPlayer, setEditPlayer] = useState();
  const [savingState, setSavingState] = useState(0);

  useEffect(() => {
    setEditPlayer({ player: { ...fighters?.find((p) => p._id === teamId) } });
  }, [fighters, teamId]);

  function save() {
    setSavingState("SAVING");

    updatePlayer(editPlayer?.player).then((data) => {
      onUpdate();
      setTimeout(() => {
        setSavingState("SAVED");
        setTimeout(() => {
          setSavingState(0);
        }, 1000);
      }, 1000);
    });
  }

  return editPlayer ? (
    <PlayerMain>
      <Grid>
        <Grid.Unit size={3 / 3} style={{ marginTop: "1em" }}>
          <Panel>
            <PanelTitle title="Fighter Details"></PanelTitle>
            <PanelContent>
              <PlayerEdit
                player={editPlayer}
                onChange={(p) => {
                  setEditPlayer(p);
                }}
              />
            </PanelContent>
            <SavePanel
              showDelete={false}
              state={savingState}
              onSave={() => {
                save();
              }}
              style={{ marginLeft: "auto" }}
            />
          </Panel>
        </Grid.Unit>
      </Grid>
    </PlayerMain>
  ) : (
    <></>
  );
}
