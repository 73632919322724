import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Grid from "styled-components-grid";

import SavePanel from "../UI/Form/SavePanel";

import { DataContext } from "../../contexts/DataContext";

import Hero from "../UI/Hero/Hero";
import HeroTeam from "../UI/Hero/HeroTeam";
import { SocketContext } from "../../contexts/SocketContext";
import Team from "../Team/Team";
import { Button } from "../../Theme/Hyper";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ConfirmModal from "../Modals/ConfirmModal";
import Input from "../UI/Form/Input";
import PlayerEdit from "../Player/PlayerEdit";
import CreateFixture from "../Modals/CreateFixture/CreateFixture";

const DELETE_GAME = gql`
  mutation DeleteGame($id: String!) {
    deleteGame(id: $id) {
      _id
    }
  }
`;

const GAME_BY_ID = gql`
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      title
      time
      description
      date
      commentators {
        first_name
        last_name
      }
      stadium {
        opta_name
        name
      }
      competition {
        _id
        name
        badge
        secondary_badge
        competition_type
        active_fight {
          _id
        }
      }
      season {
        _id
        name
      }
      home_team_kit
      away_team_kit
      home_fighter {
        _id
        first_name
        last_name
        first_name_upper
        last_name_upper
        short_name
        short_name_upper
        weight
        shorts_colour
        image
        image_2
        stories
        nationality {
          _id
          code
          name
        }
      }
      away_fighter {
        _id
        first_name
        last_name
        first_name_upper
        last_name_upper
        short_name
        short_name_upper
        weight
        shorts_colour
        image
        image_2
        stories
        nationality {
          _id
          code
          name
        }
      }
      live_data
      number_of_rounds
      gold_fight
      fight_number
      championship_bar
      round_minutes
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      live_data
      home_team_kit
      away_team_kit
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 52px;
  margin-bottom: 52px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 70px;
  margin-left: 200px;
`;

const DateDiv = styled.div`
  display: flex;
  margin-right: 1em;
`;

const SideBar = styled.div`
  width: 200px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;
  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;

const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  height: calc(100vh - 40px);
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;

const Score = styled.div`
  display: flex;
  font-size: 3em;

  justify-content: center;
`;

const TitleButtons = styled.div`
  display: flex;
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  justify-content: center;
`;
const KOTime = styled.div`
  display: flex;

  justify-content: center;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;
const Tab = styled.div`
  display: flex;
  padding: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 5px;
  background-color: ${(props) => (props.selected ? "#14ec8e" : "#e9ecef")};
  color: ${(props) => (props.selected ? "white" : "")};
  border-radius: 4px;
  cursor: pointer;
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function Fixture() {
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [deleteGame, { data: deleteGameData }] = useMutation(DELETE_GAME);

  const [koDate, setKODate] = useState();
  const [koTime, setKOTime] = useState();
  const [fixture, setFixture] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [awayTeam, setAwayTeam] = useState();
  const [teams, setTeams] = useState();
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedView, setSelectedView] = useState();
  const [selectedStatTab, setSelectedStatTab] = useState(0);
  const [editOfficial, setEditOfficial] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [commentators, setCommentators] = useState([]);
  const [numberOfRounds, setNumberOfRounds] = useState();
  const [roundMinutes, setRoundMinutes] = useState();
  const [title, setTitle] = useState();
  const [goldFight, setGoldFight] = useState();
  const [championshipBar, setChampionshipBar] = useState();
  const [fightNumber, setFightNumber] = useState();
  let { url: path } = useRouteMatch();
  let location = useLocation();
  let history = useHistory();
  const [showEdit, setShowEdit] = useState();

  const { fixtureId } = useParams();
  const [savingState, setSavingState] = useState(0);

  const { updateGameCommentators, updateGame, updateCompetition } =
    useContext(DataContext);

  const { game, players, subscribeToGame, sendData } =
    useContext(SocketContext);

  useEffect(() => {
    if (fixtureId) {
      getGame({ variables: { id: fixtureId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureId]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore && fixtureId) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: fixtureId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.gameUpdated) return prev;
          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };
          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, fixtureId]);

  useEffect(() => {
    if (data) {
      setNumberOfRounds(data?.gameById?.number_of_rounds);
      setGoldFight(data?.gameById?.gold_fight);
      setTitle(data?.gameById?.title);
      setKODate(moment(data.gameById.date));
      setKOTime(moment(data.gameById.time, "HH:mmZ"));
      setFixture(data.gameById);
      setChampionshipBar(data.championshipBar);
      setRoundMinutes(data?.gameById?.roundMinutes);
      setCommentators([...(data.gameById?.commentators || [])]);
    }
  }, [data]);

  return (
    <>
      {/* <PopupBase></PopupBase> */}
      <Main>
        <SideBar>
          <Ul>
            <Li>
              <A
                selected={selectedView == "info"}
                selectedBG={selectedView == "info"}
                onClick={() => {
                  setSelectedView("info");
                }}
                to={`${path}`}
              >
                Fight Info
              </A>
            </Li>
            <Li>
              <A
                selected={selectedView == "home"}
                selectedBG={selectedView == "home"}
                onClick={() => {
                  setSelectedView("home");
                }}
                to={`${path}/${fixture?.home_fighter?._id}/${fixture?.season?._id}`}
              >
                {fixture?.home_fighter?.first_name}{" "}
                {fixture?.home_fighter?.last_name}
              </A>
            </Li>
            <Li>
              <A
                selected={selectedView == "away"}
                selectedBG={selectedView == "away"}
                onClick={() => {
                  setSelectedView("away");
                }}
                to={`${path}/${fixture?.away_fighter?._id}/${fixture?.season?._id}`}
              >
                {fixture?.away_fighter?.first_name}{" "}
                {fixture?.away_fighter?.last_name}
              </A>
            </Li>
          </Ul>
        </SideBar>
        {/* <Title>New Fixture</Title> */}
        <Content>
          <Hero>
            <HeroTeam style={{ justifyContent: "flex-end" }}>
              {fixture?.home_fighter?.first_name}{" "}
              {fixture?.home_fighter?.last_name}
            </HeroTeam>

            <Middle>
              <Score>
                {game?.home_scorers?.length || 0}-
                {game?.away_scorers?.length || 0}
              </Score>
              <KOTime>
                {koTime?.hours().toString().padStart(2, "0")}:
                {koTime?.minutes().toString().padStart(2, "0")}
              </KOTime>
            </Middle>

            <HeroTeam style={{ justifyContent: "flex-start" }}>
              {fixture?.away_fighter?.first_name}{" "}
              {fixture?.away_fighter?.last_name}
            </HeroTeam>
          </Hero>
          <Switch>
            {/* <Route path={`${path}/boxes`}>
              <CornerBoxes fixture={fixture} />
            </Route> */}
            <Route path={`${path}/season`}>Season</Route>

            <Route path={`${path}/:teamId/:seasonId`}>
              {fixture && (
                <Player
                  fighters={[
                    fixture?.home_fighter || { _id: "" },
                    fixture?.away_fighter || { _id: "" },
                  ]}
                  onUpdate={(p) => {
                    //    selectPlayer(p);
                  }}
                />
              )}
            </Route>
            <Route path={`${path}`}>
              <Grid>
                <Grid.Unit size={1 / 3}>
                  <Panel>
                    <PanelTitle title="Fight"></PanelTitle>
                    <PanelContent>
                      <Button
                        green={
                          fixture?.competition?.active_fight?._id ===
                          fixture?._id
                        }
                        onClick={() => {
                          let newCompetition = {
                            ...fixture?.competition,
                            active_fight: fixture._id,
                          };
                          updateCompetition({ data: newCompetition }).then(
                            () => {
                              getGame({ variables: { id: fixture._id } });
                            }
                          );
                        }}
                      >
                        Active
                      </Button>

                      <Button
                        onClick={() => {
                          setShowEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        red
                        onClick={() => {
                          setConfirmDelete(true);
                        }}
                      >
                        Delete
                      </Button>
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 3}>
                  <Panel>
                    <PanelTitle title="Kick-Off"></PanelTitle>
                    <PanelContent>
                      <DateDiv>{koDate?.format("dddd Do  MMMM YYYY")}</DateDiv>
                      <DateDiv>
                        {koTime?.hours().toString().padStart(2, "0")}:
                        {koTime?.minutes().toString().padStart(2, "0")}
                      </DateDiv>
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 3}>
                  <Panel>
                    <PanelTitle title="Venue"></PanelTitle>
                    <PanelContent>{fixture?.stadium?.name}</PanelContent>
                  </Panel>
                </Grid.Unit>
                <Grid.Unit size={1 / 3} style={{ marginTop: "10px" }}>
                  <Panel>
                    <PanelTitle title="Fight Settings"></PanelTitle>
                    <PanelContent style={{ flexDirection: "column" }}>
                      {/* <Input
                        value={fightNumber || fixture?.fight_number}
                        placeholder="Fight number"
                        onChange={(e) => {
                          setFightNumber(e.target.value);
                        }}
                      /> */}
                      <Input
                        title="Number of rounds"
                        value={numberOfRounds || fixture?.number_of_rounds}
                        placeholder="Number of Rounds"
                        onChange={(e) => {
                          setNumberOfRounds(e.target.value);
                        }}
                      />
                      <Input
                        title="Round minutes"
                        value={roundMinutes || fixture?.round_minutes}
                        placeholder=""
                        onChange={(e) => {
                          setRoundMinutes(e.target.value);
                        }}
                      />
                      <Input
                        title="Title"
                        value={title || fixture?.title}
                        placeholder="Title"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                      <Input
                        title="Gold fight (1 or 0)"
                        value={goldFight || fixture?.gold_fight}
                        placeholder="Gold fight"
                        onChange={(e) => {
                          setGoldFight(e.target.value);
                        }}
                      />
                      <Input
                        title="Championship bar text"
                        value={championshipBar || fixture?.championship_bar}
                        placeholder=""
                        onChange={(e) => {
                          setChampionshipBar(e.target.value);
                        }}
                      />
                    </PanelContent>
                    <SavePanel
                      state={savingState}
                      onCancel={() => {
                        setNumberOfRounds(fixture?.number_of_rounds);
                      }}
                      onSave={() => {
                        setSavingState("SAVING");
                        updateGame({
                          id: fixture._id,
                          number_of_rounds: parseInt(numberOfRounds),
                          fight_number: parseInt(fightNumber),
                          title: title,
                          gold_fight: parseInt(goldFight),
                          championship_bar: championshipBar,
                          round_minutes: parseInt(roundMinutes),
                        }).then(() => {
                          setSavingState("SAVED");
                          setTimeout(() => {
                            setSavingState(0);
                          }, 1000);
                        });
                      }}
                      style={{ marginLeft: "auto" }}
                    />
                  </Panel>
                </Grid.Unit>
              </Grid>
            </Route>
          </Switch>
        </Content>

        <ConfirmModal
          title="Are you sure you want to delete this fight?"
          show={confirmDelete}
          onConfirm={() => {
            deleteGame({ variables: { id: fixture._id } });
            setConfirmDelete(false);
            history.push("/");
          }}
          onCancel={() => {
            setConfirmDelete(false);
          }}
        ></ConfirmModal>

        <CreateFixture
          edit={true}
          fixture={fixture}
          show={showEdit}
          onCancel={() => {
            setShowEdit(false);
          }}
          onSaved={() => {
            //getData();
            setShowEdit(false);
          }}
        />
      </Main>
    </>
  );
}

function Player({ fighters, onUpdate }) {
  let { teamId, seasonId } = useParams();
  const { updatePlayer } = useContext(DataContext);
  let [editPlayer, setEditPlayer] = useState();
  const [savingState, setSavingState] = useState(0);

  useEffect(() => {
    setEditPlayer({ player: { ...fighters?.find((p) => p._id === teamId) } });
  }, [fighters, teamId]);

  function save() {
    setSavingState("SAVING");

    updatePlayer(editPlayer?.player).then((data) => {
      onUpdate();
      setTimeout(() => {
        setSavingState("SAVED");
        setTimeout(() => {
          setSavingState(0);
        }, 1000);
      }, 1000);
    });
  }

  return editPlayer ? (
    <Grid>
      <Grid.Unit size={3 / 3} style={{ marginTop: "1em" }}>
        <Panel>
          <PanelTitle title="Fighter Details"></PanelTitle>
          <PanelContent>
            <PlayerEdit
              player={editPlayer}
              onChange={(p) => {
                setEditPlayer(p);
              }}
            />
          </PanelContent>
          <SavePanel
            showDelete={false}
            state={savingState}
            onSave={() => {
              save();
            }}
            style={{ marginLeft: "auto" }}
          />
        </Panel>
      </Grid.Unit>
    </Grid>
  ) : (
    <></>
  );
}
