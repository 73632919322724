import React, { useContext, useState } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Navigation/Sidebar/Sidebar";
import styled from "styled-components";

import { UIContext } from "../../../contexts/UIContext";
import { DataContext } from "../../../contexts/DataContext";
import Breadcrumb from "../../Navigation/Breadcrumb";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  display: flex;
  margin-top: 60px;
  margin-left: ${(props) => (props.fullScreen ? "0px" : "230px")};
  transition: all 0.2s ease-in-out;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default function Main({ children }) {
  const { navigationView, showSideBar } = useContext(UIContext);
  const { teams, players, fixtures } = useContext(DataContext);
  const [showNewFixture, setShowNewFixture] = useState(false);
  return (
    <MainDiv>
      <Header />
      <Sidebar />
      <Content fullScreen={!showSideBar}>
        <Column>
          <Breadcrumb />
          {children}
        </Column>
      </Content>
    </MainDiv>
  );
}
